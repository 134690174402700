import React from "react";
import { useThemeColor } from "../../../hooks/useThemeColor";

import { rootUrl } from "../../../App";

const ResultItem = ({ gameItem }) => {
    const { colorMode } = useThemeColor();

    return (
        <div className="result-item py-1">
            {/* {
                (gameItem?.events?.[0]?.live_link) && */}
            <div className="row gx-0 d-flex justify-content-center align-items-center pb-2">
                <div className="col-4">
                    <div className="fw-bold red-text live-text py-1">
                        {"Live"}
                    </div>
                </div>
                {
                    (gameItem?.events?.[0]?.live_link) &&
                    <div className="col-12">
                        <div className="mt-2">
                            <div className="d-flex justify-content-center align-items-center video-frame">
                                <iframe className="" width="" height="" src={gameItem?.events?.[0]?.live_link} title={"title"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" marginWidth="0" marginHeight="0" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/* } */}
            <div className="radius-10">
                {
                    (gameItem?.events?.length) &&
                    gameItem?.events?.map((item, index) =>
                        <div key={item?.id || index} className="row gx-0 d-flex justify-content-center align-items-center individual-type px-1 py-1">
                            <div className="col-1 col-md-1 result-item-icon">
                                <img className="mb-0"
                                    src={rootUrl + item?.game_type?.icon} alt={item?.game_type?.name}
                                    width={"70%"}
                                    height={"auto"}
                                />
                            </div>
                            <div className="col-3 col-md-3 text-start text-uppercase result-item-name ">
                                <p className="mb-0 pb-0 ps-2">
                                    {item?.game_type?.name}
                                </p>
                            </div>
                            <div className="col-8 col-md-8 d-flex justify-content-end align-items-center text-white">
                                <div className="row gx-0 d-flex flex-row justify-content-center align-items-center">
                                    <div className="col d-flex justify-content-around align-items-center">
                                        {
                                            (item?.win_numbers) &&
                                            item?.win_numbers?.split("-")?.map((winItem, index) =>
                                                <p key={index + winItem} className="mb-0 pb-0 me-1 win-item d-flex justify-content-center align-items-center">
                                                    {winItem}
                                                </p>
                                            )
                                        }
                                    </div>
                                    <div className="col mb-0 pb-0 ps-1 ms-2 total d-flex justify-content-end align-items-center"
                                        style={{ borderLeft: (colorMode === "dark") ? "1px solid var(--placeholder)" : "1px solid var(--placeholder)", }}
                                    >
                                        <span className="">
                                            {`Total `}
                                        </span>
                                        <p className="pb-0 mb-0 ms-1 total-count text-white d-flex justify-content-center align-items-center">
                                            {item?.total}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ResultItem;