import { createSlice } from "@reduxjs/toolkit";

const detailsSweetAlertSlice = createSlice({
    name: "detailsSweetAlert",
    initialState: {
        show: false,
        message: null,
        type: null,
        item: null
    },
    reducers: {
        showDetailsSweetAlert: (state, action) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.item = action.payload.item;
        },

        closeDetailsSweetAlert: (state, action) => {
            state.show = false;
            state.message = null;
            state.type = null;
            state.item = null;
        }
    }
})

export const detailsSweetAlertData = state => {
    const data = {
        show: state.detailsSweetAlert?.show,
        message: state.detailsSweetAlert?.message,
        type: state.detailsSweetAlert?.type,
        item: state.detailsSweetAlert?.item
    }
    return data?.show ? data : state?.detailsSweetAlert;
};

export const { showDetailsSweetAlert, closeDetailsSweetAlert } = detailsSweetAlertSlice.actions;

export default detailsSweetAlertSlice.reducer;