import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { rootUrl, userKey } from "../../../App";

import { useThemeColor } from "../../../hooks/useThemeColor";
import LotteryAccordion from "./LotteryAccordion";
import "./LotteryLauncher.css";

import LotteryHistoryModal from "./LotteryHistoryModal";

import { ImCross } from "react-icons/im";

const aboutDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/about-dark.svg";
const aboutLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/about-light.svg";

const myBetsDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/my-bets-dark.svg";
const myBetsLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/my-bets-light.svg";

const liveResultDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/live-result-dark.svg";
const liveResultLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/live-result-light.svg";

const LotteryLauncher = () => {
    const navigate = useNavigate();

    const [searchParams,] = useSearchParams();
    const { colorMode } = useThemeColor();

    const p_code = searchParams.get("p_code");
    const game_id = searchParams.get("game_id");
    // /lottery/game-events/{p_code}/{game_id}/

    const [lotteryData, setLotteryData] = useState(null);
    const [showLotteryHistoryModal, setShowLotteryHistoryModal] = useState([false, {}]);
    const [activeKey, setActiveKey] = useState(lotteryData?.[0]?.id || null);
    const [showLiveTV, setShowLiveTV] = useState(null);

    useEffect(() => {
        // Ensure that the activeKey is set correctly when items change
        if (!activeKey && lotteryData?.length > 0) {
            setActiveKey(lotteryData[0]?.id);
        }
    }, [lotteryData?.length, activeKey]);

    const lotteryLauncherNav = [
        {
            id: 0,
            name: `About`,
            alt: `about`,
            darkIcon: aboutDark,
            lightIcon: aboutLight,
            to: `/about/${game_id}`,
        },
        {
            id: 1,
            name: `My Bets`,
            alt: `my-bets`,
            darkIcon: myBetsDark,
            lightIcon: myBetsLight,
            to: `/my-bets`,
        },
        {
            id: 2,
            name: `Live Results`,
            alt: `live-results`,
            darkIcon: liveResultDark,
            lightIcon: liveResultLight,
            to: `/lottery-live-results/${p_code}/${game_id}`,
        },
    ];

    useEffect(() => {
        // console.log(p_code, p_type);
        let stringValue = localStorage.getItem(userKey);
        let value = JSON.parse(stringValue);

        if (!lotteryData && value?.value) {
            // dispatch(setInnerLoaderTrue());

            let fullUrl = `${rootUrl}/lottery/game-events/${p_code}/${game_id}/`;

            fetch(fullUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok") {
                        // dispatch(setInnerLoaderFalse());
                        setLotteryData(data?.data);
                        // window.scrollTo(0, 1);
                        return;
                    }
                    if (data?.status === "failed") {
                        // setLaunchUrl(null);
                        // const matchedError = findErrorByCode(data?.errCode);
                        // if (matchedError) {
                        //     showGameErrorsSweetAlert2(matchedError, true);
                        //     // navigate(-1);
                        // }
                        return;
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse());
                })
        }
    }, [lotteryData]);

    // console.log(lotteryData);

    return (
        <div>
            <div className="row gx-0 d-flex justify-content-end align-items-end my-2 px-2">
                <div className="col-12">
                    <div className="row gx-0 d-flex justify-content-between align-items-end">
                        {
                            lotteryLauncherNav?.map((item, index) =>
                                <div key={item?.id || index} className="col">
                                    <div
                                        onClick={() => {
                                            if (item?.to === "/my-bets") {
                                                setShowLotteryHistoryModal([true, item]);
                                            } else {
                                                navigate(item?.to);
                                            }
                                        }
                                        }
                                        className={
                                            (colorMode === "dark") ?
                                                "d-flex justify-content-center align-items-center blackish-ash-background text-white rounded-pill py-1 mx-1 pointer"
                                                :
                                                "d-flex justify-content-center align-items-center gray-background text-dark rounded-pill py-1 mx-1 pointer"
                                        }>
                                        <img className="mb-0"
                                            src={(colorMode === "dark") ? item?.darkIcon : item?.lightIcon} alt={item?.alt}
                                            width={"15"} height={"15"}
                                        />
                                        <p className="mb-0 pb-0 ps-2">
                                            {item?.name}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={(colorMode === "dark") ? "mx-2 dark-background" : "mx-2 light-blue-background"}>
                {
                    lotteryData &&
                    lotteryData?.map((item, index) =>
                        <LotteryAccordion key={item?.id || index} activeKey={activeKey} setActiveKey={setActiveKey} item={item} setLotteryData={setLotteryData} setShowLiveTV={setShowLiveTV} />
                    )
                }
            </div>
            {
                showLotteryHistoryModal[0] &&
                <LotteryHistoryModal
                    show={showLotteryHistoryModal[0]}
                    close={() => { setShowLotteryHistoryModal([false, {}]) }}
                    data={showLotteryHistoryModal[1]}
                />
            }
            {
                (showLiveTV?.live_link) &&
                <div className="row gx-0 d-flex justify-content-end align-items-end p-2">
                    <div className="col-4 col-md-4 col-lg-4 tv-container-sticky sticky">
                        <div className="hide-tv">
                            <ImCross onClick={() => setShowLiveTV(null)} className="pointer" fill={"var(--white)"} size={"15"} />
                        </div>
                        <div className={showLiveTV?.live_link ? "tv-container" : "d-none"}
                        >
                            <iframe
                                id="lotteryIframe2"
                                className="tv-iframe"
                                title="lotteryIframe2"
                                src={showLiveTV?.live_link}
                            ></iframe>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
};

export default LotteryLauncher;