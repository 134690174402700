import React, { useState, useEffect } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { AiFillCaretDown } from "react-icons/ai";
import { useThemeColor } from "../../../hooks/useThemeColor";
import HistoryItem from "./HistoryItem";

const ContextAwareToggle = ({ children, eventKey, callback, activeKey, setActiveKey, arrow, setArrow, colorMode }) => {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    );

    const handleClick = () => {
        const isActive = activeKey === eventKey;
        setActiveKey(isActive ? null : eventKey);
        setArrow(!isActive);
        decoratedOnClick();
    };

    return (
        <button
            type="button"
            className="px-0 py-1"
            style={{
                backgroundColor: colorMode === "dark" ? "var(--blackish-ash)" : "var(--placeholder)",
                color: colorMode === "dark" ? "var(--white)" : "var(--dark)",
                border: "none",
                outline: "none",
                width: "100%",
                textAlign: "start",
                borderRadius: !arrow ? "10px" : "10px 10px 0 0"
            }}
            onClick={handleClick}
        >
            {children}
        </button>
    );
};

const HistoryAccordion = ({ item, activeKey, setActiveKey }) => {
    const { colorMode } = useThemeColor();
    const [arrow, setArrow] = useState(false);

    useEffect(() => {
        if (activeKey === item?.id) {
            setArrow(true);
        } else {
            setArrow(false);
        }
    }, [activeKey, item?.id]);

    return (
        <Accordion activeKey={activeKey} className={item?.id ? "lottery-launcher-history" : "d-none"}>
            <Card className="my-0 py-0 w-100">
                <Card.Header className="p-0" style={{ borderBottom: colorMode === "dark" ? "2px solid transparent" : "2px solid var(--light-blue)" }}>
                    <ContextAwareToggle
                        eventKey={item?.id}
                        activeKey={activeKey}
                        setActiveKey={setActiveKey}
                        arrow={arrow}
                        setArrow={setArrow}
                        colorMode={colorMode}
                        className="position-relative"
                    >
                        <div className="d-flex ps-2 accordion-font">
                            <div className="ps-2 pe-5">
                                <span className={"fw-bold rounded-pill px-2 py-0 " + ((item?.status === "Pending") ? "golden-background" : (item?.status === "Win") ? "light-green-background" : (item?.status === "Loss") ? "red-background" : "")} style={{ color: colorMode === "dark" ? "var(--white)" : "var(--white)" }}>
                                    {item?.d_stake_payment}
                                </span>
                                <span className={"fw-bold rounded-pill px-2 py-0 ms-1 " + ((item?.status === "Pending") ? "golden-background" : (item?.status === "Win") ? "light-green-background" : (item?.status === "Loss") ? "red-background" : "")} style={{ color: colorMode === "dark" ? "var(--white)" : "var(--white)" }}>
                                    {item?.r_stake_payment}
                                </span>
                                <span className="fw-bold ms-1" style={{ color: colorMode === "dark" ? "var(--white)" : "var(--dark)" }}>
                                    {item?.game_type || "N/A"}
                                </span>
                                <span className="fw-normal" style={{ color: colorMode === "dark" ? "var(--white)" : "var(--dark)" }}>
                                    ({item?.bet_numbers || "N/A"})
                                </span>
                                <span
                                    className="position-absolute"
                                    style={{
                                        right: 20,
                                        top: 6,
                                        transform: arrow ? "rotate(180deg)" : "rotate(360deg)",
                                        transitionDuration: "0.7s"
                                    }}
                                >
                                    <AiFillCaretDown fill={colorMode === "dark" ? "var(--white)" : "var(--dark)"} />
                                </span>
                            </div>
                        </div>
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={item?.id}>
                    <Card.Body className="p-0 m-0">
                        <HistoryItem historyItem={item} arrow={arrow} />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default HistoryAccordion;