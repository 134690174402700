import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { GameErrorsDataList } from "../Data/GameErrorsDataList";
import { useThemeColor } from "./useThemeColor";

import Swal from "sweetalert2";
// import { createPortal } from "react-dom";
import withReactContent from "sweetalert2-react-content";

const gameAlert0 = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameAlertIcons/game-alert-0.svg";
const gameAlert1 = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameAlertIcons/game-alert-1.svg";
const gameAlert2 = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameAlertIcons/game-alert-1.svg";

const useGameErrors = () => {

    const navigate = useNavigate();
    const { colorMode } = useThemeColor();
    const [errorAlert, setErrorAlert] = useState(null);

    const MySwal = withReactContent(Swal);

    const findErrorByCode = (code) => {
        for (const errors of GameErrorsDataList) {
            if (errors?.errCode?.includes(code)) {
                return errors;
            }
        }
        return null; // Return null if no matching error code is found
    }


    const openErrorAlert = (alertItem) => {

        setErrorAlert({
            visible: true,
            item: alertItem
        });
    }

    const closeErrorAlert = (isNavigate) => {
        setErrorAlert({
            visible: false,
            item: null
        });
        if (isNavigate) {
            navigate(-1);
        }
    }

    const showGameErrorsSweetAlert2 = (alertItem, isNavigate = false) => {
        // console.log(alertItem);
        MySwal.fire({
            icon: (alertItem?.id === 0) ? "warning" : "success",
            iconHtml: `<img src=${(alertItem?.id === 0) ? gameAlert0 : (alertItem?.id === 1) ? gameAlert1 : (alertItem?.id === 2) ? gameAlert2 : gameAlert0} alt="alert" />`,
            showConfirmButton: false,
            background: (colorMode === "dark") ? "var(--dark)" : (colorMode === "light") ? "var(--white)" : "var(--red)",
            color: (colorMode === "dark") ? "var(--white)" : (colorMode === "light") ? "var(--dark)" : "var(--red)",
            timer: 3000,
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            closeButtonHtml: "&times;",
            didOpen: () => openErrorAlert(alertItem),
            didClose: () => closeErrorAlert(isNavigate),
        }).then(() => {
            // return dispatch(closeCustomSweetAlert());
        })
    }

    return {
        findErrorByCode,
        openErrorAlert,
        closeErrorAlert,
        showGameErrorsSweetAlert2,
        errorAlert,
    };
};

export default useGameErrors;