import React from "react";
import { useParams } from "react-router-dom";
import { useLotteryAbout } from "../../../hooks/useLotteryAbout";

const LotteryAbout = () => {
    const game_id = useParams();
    const { lotteryAbout } = useLotteryAbout(game_id);
    return (
        <div className="px-2 py-3"
            dangerouslySetInnerHTML={{ __html: lotteryAbout?.about }}
        >
        </div>
    );
};

export default LotteryAbout;