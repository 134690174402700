import React from "react";

import { useThemeColor } from "../../../hooks/useThemeColor";
import useTime from "../../../hooks/useTime";

const HistoryItem = ({ historyItem, }) => {
    const { colorMode } = useThemeColor();
    const { convertDate, convertTime } = useTime();

    const showableHistoryItem = {
        "Transaction Date": convertDate(historyItem?.created_at) + " " + convertTime(historyItem?.created_at),
        "Event Type": historyItem?.event_type,
        "Bet code": historyItem?.bet_code,
        "Odds Rate D stake": historyItem?.d_stake_rate,
        "Odds Rate R stake": historyItem?.r_stake_rate,
        "D. Payout": historyItem?.d_stake_payout,
        "R. Payout": historyItem?.r_stake_payout,
        "Status": historyItem?.status,
    };
    return (
        <div className="row gx-0 px-2 game-item"
            style={{
                backgroundColor: (colorMode === "dark") ? "var(--dark-gray)" : "var(--white)",
                color: (colorMode === "dark") ? "var(--white)" : "var(--dark)",
                borderRadius: "0 0 10px 10px",
            }}
        >
            <div className="col-12">
                <div className="row gx-0 d-flex justify-content-center align-items-center">
                    <div className="col-12 d-flex justify-content-center align-items-center"
                        style={{
                            borderBottom: "1px dashed var(--gray)",
                        }}
                    >
                        <div className="">
                            <p className="mb-0 py-2">
                                {historyItem?.game_type}({historyItem?.bet_numbers})
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row gx-0 d-flex justify-content-center align-items-center">
                    <div className="col-12">
                        <div className="row gx-0 gy-2 my-2 w-100 d-flex align-items-center justify-content-between">
                            {Object.keys(showableHistoryItem)?.map(function (property, key) {
                                // console.log(item, property, key);
                                return (
                                    <>
                                        <div className="col-6 text-start">
                                            <div className="">{property} :</div>
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className=""
                                                style={{
                                                    color:
                                                        (showableHistoryItem[property] === "Win") ? "var(--light-green)" :
                                                            (showableHistoryItem[property] === "Loss") ? "var(--red)" :
                                                                (showableHistoryItem[property] === "Pending") ? "var(--golden)" :
                                                                    ""
                                                }}
                                            >{showableHistoryItem[property]}</div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryItem;