import React, { useEffect, useState } from 'react';
import "./Navigation.css";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { currentUserWallet, fetchLoginUserWallet, userDetail } from "../../../Redux/userSlice";

// import images and icons

import { useThemeColor } from "../../../hooks/useThemeColor";
import { AiFillLeftCircle } from 'react-icons/ai';

const signUpIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/signup-icon.svg";
const loginIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/login-icon.svg";

const userIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-icon.svg";
const userDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-dark-icon.svg";

const LotteryNavigationWithBackButtonPageName = ({ pageTitle, state = null, setState = null, unseenNotifications = null }) => {
    const { colorMode, } = useThemeColor();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(userDetail);
    const userWallet = useSelector(currentUserWallet);

    const [activeRefresh, setActiveRefresh] = useState(true);

    useEffect(() => {
        let refreshTimer;
        if (activeRefresh) {
            // Start refreshing

            dispatch(fetchLoginUserWallet());
            refreshTimer = setTimeout(() => {
                // Stop refreshing after 1 second
                setActiveRefresh(false);
            }, 1000);
        }
        return () => clearInterval(refreshTimer);
    }, [activeRefresh, dispatch]);

    return (
        <div className={
            "row gx-0 justify-content-between align-items-center py-1 px-2 header"
        }>
            < div className="col-3 col-sm-5 d-flex justify-content-start justify-content-md-start align-items-center position-relative" >
                <div className="">
                    <AiFillLeftCircle size={"30"} fill={(colorMode === "light") ? "var(--chocolate)" : "var(--golden)"} onClick={() => {
                        if (state) {
                            setState();
                        } else {
                            navigate(-1);
                        }
                    }} style={{ cursor: "pointer", marginRight: "5px" }} />
                </div>
                <h1 className="fw-bold text-uppercase mb-0 py-0" style={{ color: (colorMode === "light") ? "var(--chocolate)" : "var(--golden)", textDecoration: "none", fontSize: "0.8rem", whiteSpace: "nowrap", }}>
                    {pageTitle}
                </h1>
            </div >
            {
                user?.is_login ?
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        <span className="pe-1 pe-md-2">{parseFloat((+userWallet?.credit_balance || 0.00)).toFixed(2)}</span>

                        <div
                            className="nav-user-bg"
                        // onClick={() => navigate("/account")}
                        >
                            <img className="p-1" src={(colorMode === "light") ? userIcon : userDarkIcon} alt="user" />
                        </div>
                    </div >
                    :
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        <Link to={"/registration"}>
                            <button className="header-button signUp-button">
                                <img className="me-1"
                                    width={"11px"}
                                    src={signUpIcon}
                                    alt="signUp" />
                                {/* <Trans i18nKey="signup"> */}
                                SIGN UP
                                {/* </Trans> */}
                            </button>
                        </Link>

                        <Link to="/login" className="ms-2">
                            <button className="header-button login-button">
                                <img className="me-1"
                                    width={"14px"}
                                    src={loginIcon}
                                    alt="login" />
                                {/* <Trans i18nKey="login"> */}
                                LOG IN
                                {/* </Trans> */}
                            </button>
                        </Link>
                    </div>
            }
        </div >
    );
};

export default LotteryNavigationWithBackButtonPageName;