import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { rootUrl, userKey } from "../../../App";

import { useThemeColor } from "../../../hooks/useThemeColor";
import ResultsAccordion from "./ResultsAccordion";

// import { useLotteryResults } from "../../../hooks/useLotteryResults";

const LotteryResults = () => {
    const { colorMode } = useThemeColor();
    const { p_code, game_id } = useParams();
    const [lotteryResult, setLotteryResult] = useState(null);
    // const { lotteryResults } = useLotteryResults(p_code, game_id);
    // console.log(p_code, game_id);
    const [activeKey, setActiveKey] = useState(lotteryResult?.[0]?.events?.[0]?.id || null);

    useEffect(() => {
        if (!activeKey && lotteryResult?.length > 0) {
            setActiveKey(lotteryResult?.[0]?.events?.[0]?.id);
        }
    }, [lotteryResult, activeKey]);

    useEffect(() => {
        // console.log(p_code, p_type);
        let stringValue = localStorage.getItem(userKey);
        let value = JSON.parse(stringValue);

        if (!lotteryResult && value?.value) {
            // dispatch(setInnerLoaderTrue());

            let fullUrl = `${rootUrl}/lottery/game-events/${p_code}/${game_id}/?result=yes`;

            fetch(fullUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok") {
                        // dispatch(setInnerLoaderFalse());
                        setLotteryResult(data?.data);
                        // window.scrollTo(0, 1);
                        return;
                    }
                    if (data?.status === "failed") {
                        return;
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse());
                })
        }
    }, [lotteryResult]);

    // console.log(lotteryResult);

    return (
        <>
            <div className={(colorMode === "dark") ? "mx-2 my-2 dark-background" : "mx-2 my-2 light-blue-background"}>
                {
                    lotteryResult &&
                    lotteryResult?.map((item, index) =>
                        <ResultsAccordion key={item?.events?.[0]?.id || index} activeKey={activeKey} setActiveKey={setActiveKey} item={item} />
                    )
                }
            </div>
        </>
    );
};

export default LotteryResults;