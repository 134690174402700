

export const GameErrorsDataList = [
    {
        id: 0,
        errCode: [
            "70", "98", "600", "999", "-998", "603", "9998", "1028", "1031", "1047", "1046", "998",
        ],
        text: `Under Maintenance, Try Again Later`,
        // icon: ``,
        color: "var(--golden)",
    },
    {
        id: 1,
        errCode: [
            "994", "995", "996",
        ],
        text: `Game will be Coming Soon`,
        // icon: ``,
        color: "var(--light-green)",
    },
    {
        id: 2,
        errCode: [
            "1002", "81",
        ],
        text: `Try Again`,
        // icon: ``,
        color: "var(--light-green)",
    },
    {
        id: 3,
        errCode: [
            "204",
        ],
        text: `Insufficient Balance. Deposit and Try Again.`,
        // icon: ``,
        color: "var(--golden)",
    },
];