import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import loaderReducer from "./loaderSlice";
import alertReducer from "./alertSlice";
import sweetAlertReducer from "./customSweetAlertSlice";
import detailsSweetAlertReducer from "./detailsSweetAlertSlice";
import gatewayReducer from "./bankingTransactionSlice";
// import gameReducer from "./gameSlice";
// import agentReducer from "./agentAdminSlice";
// import launcherSlice from "./launcherSlice";
// import sportsExchangeReducer from "./sportsExchangeSlice";

const rootReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    customAlert: alertReducer,
    customSweetAlert: sweetAlertReducer,
    detailsSweetAlert: detailsSweetAlertReducer,
    gateway: gatewayReducer,
    // game: gameReducer,
    // agent: agentReducer,
    // launchGame: launcherSlice,
    // sportsExchange: sportsExchangeReducer,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: false,
})

export default store;