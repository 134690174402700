import React from 'react';
import './Loader.css';
import { Modal } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { loaderState } from '../../../Redux/loaderSlice';

import { RotatingLines } from 'react-loader-spinner';

const Loader = ({ color = "var(--gray)", show }) => {
    const loader = useSelector(loaderState);

    return (
        <Modal
            show={loader || show}
            backdrop="static"
            keyboard={false}
            className="loader-modal"
        >
            <Modal.Body>
                <RotatingLines
                    strokeColor={color}
                    strokeWidth="2"
                    animationDuration="1.75"
                    width="60"
                    visible={true}
                />
            </Modal.Body>
        </Modal>
    );
};

export default Loader;