import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        loaderVisible: false,
    },
    reducers: {
        setInnerLoaderTrue: (state, action) => {
            state.loaderVisible = true;
        },

        setInnerLoaderFalse: (state, action) => {
            state.loaderVisible = false;
        }
    }
})
export const loaderState = state => state.loader.loaderVisible;
export const { setInnerLoaderTrue, setInnerLoaderFalse } = loaderSlice.actions;
export default loaderSlice.reducer;