import React from "react";
import "./Navigation.css";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import ThemeChanger from "../ThemeChanger/ThemeChanger";
import { useStatics } from "../../../hooks/useStatics";
import { userDetail } from "../../../Redux/userSlice";

// import images and icons

import { FaBell } from 'react-icons/fa';

import { useThemeColor } from "../../../hooks/useThemeColor";
import { useLiveNotifications } from "../../../hooks/useLiveNotifications";

const signUpIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/signup-icon.svg";
const loginIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/login-icon.svg";

// const refreshIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/refresh-icon.svg";
// const plusIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/plus-icon.svg";

const userIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-icon.svg";
const userDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-dark-icon.svg";

const Navigation = () => {
    const { colorMode, } = useThemeColor();

    const { logo } = useStatics();
    const { unseenNotifications, } = useLiveNotifications();

    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const user = useSelector(userDetail);
    // const userWallet = useSelector(currentUserWallet);

    // const [activeRefresh, setActiveRefresh] = useState(false);

    // const refreshBtn = () => {
    //     setActiveRefresh(true);
    // };


    // useEffect(() => {
    //     let refreshTimer;
    //     if (activeRefresh) {
    //         // Start refreshing

    //         dispatch(fetchLoginUserWallet());
    //         refreshTimer = setTimeout(() => {
    //             // Stop refreshing after 1 second
    //             setActiveRefresh(false);
    //         }, 1000);
    //     }
    //     // else {
    //     //     // Start a timer to refresh after 30 seconds if not already refreshing
    //     //     refreshTimer = setTimeout(() => {
    //     //         setActiveRefresh(true);
    //     //     }, 30000);
    //     // }
    //     return () => clearInterval(refreshTimer);
    // }, [activeRefresh, dispatch]);

    // console.log(user, userWallet);

    return (
        <div className="row gx-0 justify-content-between align-items-center py-1 px-2 header">
            <div className="col-3 col-sm-5 d-flex justify-content-start justify-content-md-start">
                <Link to="/"
                // onClick={() => setSelectedFragmentID(0)}
                >
                    <img className="logo"
                        src={logo}
                        alt="logo" />
                </Link>
            </div>
            {
                user?.is_login ?
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        {/* <div className="custom-amount-button">
                            <div onClick={refreshBtn} className="d-flex align-items-center mx-2 pointer">
                                <div className="d-flex align-items-center">
                                    <img className={activeRefresh ? "rotating" : ""} src={refreshIcon} alt="refresh" height={"14px"} />
                                </div>
                            </div>
                            <span className="ps-1 ps-md-2">{userWallet ? (parseFloat((+userWallet?.credit_balance || 0) + (+userWallet?.reserve_balance || 0) + (+userWallet?.coin_balance || 0)).toFixed(2)) : "_.__"}</span>
                            <div onClick={() => { navigate(`/deposit${user?.user?.username ? ("?" + user?.user?.username) : ""}`) }} className="pointer amount-deposit-button">
                                <img className={""} src={plusIcon} alt="deposit" width={"24px"} />
                            </div>
                        </div> */}

                        <Link className={(unseenNotifications?.length > 0) ? "mx-2 bet-notification-number blink-image" : "mx-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ""}`}>
                            <FaBell size={22} color="var(--golden)" />

                            <span>{(unseenNotifications?.length > 9) ? "9+" : (unseenNotifications?.length) ? unseenNotifications?.length : ""}</span>

                        </Link>
                        {/* <Link className={seenNotifications?.length > 0 ? "mx-2 betNotificationNumber blink-image" : "mx-2 betNotificationNumber"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ""}`}>
                            <FaBell size={22} color="var(--dark)" />
                            {
                                seenNotifications?.length > 0 &&
                                <span>{(seenNotifications?.length > 9) ? "9+" : seenNotifications?.length ? seenNotifications?.length : ""}</span>
                            }
                        </Link> */}
                        {/* <ThemeChanger /> */}

                        <div
                            className="nav-user-bg pointer"
                            onClick={() => navigate("/account")}
                        >
                            <img className="p-1" src={(colorMode === "light") ? userIcon : userDarkIcon} alt="user" />
                        </div>
                    </div>
                    :
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        <Link to={"/registration"}>
                            <button className="header-button signUp-button">
                                <img className="me-1"
                                    width={"11px"}
                                    src={signUpIcon}
                                    alt="signUp" />
                                {/* <Trans i18nKey="signup"> */}
                                SIGN UP
                                {/* </Trans> */}
                            </button>
                        </Link>

                        <Link to="/login" className="ms-2">
                            <button className="header-button login-button">
                                <img className="me-1"
                                    width={"14px"}
                                    src={loginIcon}
                                    alt="login" />
                                {/* <Trans i18nKey="login"> */}
                                LOG IN
                                {/* </Trans> */}
                            </button>
                        </Link>

                        {/* <ThemeChanger /> */}
                    </div>
            }
        </div>
    );
};

export default Navigation;