import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { rootUrl } from "../App";
import { useDispatch } from "react-redux";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";
import { addDataIntoCache, getCacheData } from "../Redux/cacheSlice";

export const useStatics = () => {
    const dispatch = useDispatch();
    const [mirrorLinkData, setMirrorLinkData] = useState({});
    const [logo, setLogo] = useState([]);

    const [sliderImgList, setSliderImgList] = useState(null);
    const [appBG, setAppBG] = useState([]);
    const [promotionalsBG, setPromotionalsBG] = useState([]);

    const [percentage, setPercentage] = useState(((new Date())?.getSeconds()) % 100);
    const [progressBar, setProgressBar] = useState(true);

    const [getStartedData, setGetStartedData] = useState([]);
    const [getStartedDataState, setGetStartedDataState] = useState(true);

    const [getStartRef, setGetStartRef] = useState(localStorage.getItem("getStartAccess"));

    const setTrueStart = () => {
        let startData = localStorage.getItem("getStartAccess")
        setGetStartRef(startData);
    }

    const location = useLocation();
    let pageName = "";

    const splitForSingleImageBannersPage = location?.pathname.split("/")[1];

    pageName = splitForSingleImageBannersPage;

    useEffect(() => {
        fetch(`${rootUrl}/mirror-links/`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                if (data?.status === "ok") {
                    // console.log(data);
                    setLogo(rootUrl + data?.data?.link_logo);
                    window.document.title = document.title = data?.data?.web_title;
                    setMirrorLinkData(data?.data);
                }
            })
            .catch(err => { })
    }, [])


    useEffect(() => {
        const fetchStaticsData = async () => {
            dispatch(setInnerLoaderTrue());

            const cacheDataOfAppBG = await getCacheData('appBGCache', `${rootUrl}/statics/banners/app_background_image`);
            const cacheDataOfPromotionalsBG = await getCacheData('promotionalsBGCache', `${rootUrl}/statics/banners/download_app_bg`);

            // const cacheDataOfSliders = await getCacheData(`slidersCache`, `${rootUrl}/statics/banners/${pageName || "home"}`);
            const cacheDataOfSliders = await getCacheData(`slidersCache`, `${rootUrl}/statics/banners/`);

            if (cacheDataOfAppBG &&
                cacheDataOfPromotionalsBG &&
                cacheDataOfSliders) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfAppBG = cacheDataOfAppBG?.updateDate; /* Get the cached time from wherever it's stored */
                const cachedTimeStringOfPromotionalsBG = cacheDataOfPromotionalsBG?.updateDate;

                const cachedTimeStringOfSliders = cacheDataOfSliders?.updateDate;

                // Convert the cached time string to a Date object
                const cachedTimeOfAppBG = new Date(cachedTimeStringOfAppBG);
                const cachedTimeOfPromotionalsBG = new Date(cachedTimeStringOfPromotionalsBG);

                const cachedTimeOfSliders = new Date(cachedTimeStringOfSliders);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfAppBG = currentTime - cachedTimeOfAppBG;
                const timeDifferenceOfPromotionalsBG = currentTime - cachedTimeOfPromotionalsBG;

                const timeDifferenceOfSliders = currentTime - cachedTimeOfSliders;

                let sliderData = (cacheDataOfSliders?.data?.filter(item => item?.page_list_id?.name === (pageName || "home")));

                if ((timeDifferenceOfAppBG < cacheExpirationThreshold) && (timeDifferenceOfPromotionalsBG < cacheExpirationThreshold) && (timeDifferenceOfSliders < cacheExpirationThreshold)) {
                    setAppBG(cacheDataOfAppBG?.data);
                    setPromotionalsBG(cacheDataOfPromotionalsBG?.data);

                    setSliderImgList(sliderData);
                    return;
                }

                if (cacheDataOfAppBG) {
                    if (timeDifferenceOfAppBG < cacheExpirationThreshold) {
                        setAppBG(cacheDataOfAppBG?.data);
                        return null;
                    }
                }

                if (cacheDataOfPromotionalsBG) {
                    if (timeDifferenceOfPromotionalsBG < cacheExpirationThreshold) {
                        setPromotionalsBG(cacheDataOfPromotionalsBG?.data);
                        return null;
                    }
                }

                if (cacheDataOfSliders) {
                    if ((timeDifferenceOfSliders < cacheExpirationThreshold)) {
                        setSliderImgList(sliderData);
                        return null;
                    }
                }
                // return;
            }


            fetch(`${rootUrl}/statics/banners/`)
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok" && data?.data?.length > 0) {
                        // console.log(pageName);
                        let sliderData = (data?.data?.filter(item => item?.page_list_id?.name === (pageName || "home")));
                        setSliderImgList(sliderData);

                        addDataIntoCache(`slidersCache`, `${rootUrl}/statics/banners/`, {
                            data: data?.data,
                            updateDate: new Date()
                        });

                        // app background image is fixed not dependent on page
                        let bgData = (data?.data?.filter(item => item?.page_list_id?.name === "app_background_image"));
                        setAppBG(bgData);

                        addDataIntoCache('appBGCache', `${rootUrl}/statics/banners/app_background_image`, {
                            data: bgData,
                            updateDate: new Date()
                        });

                        // promotionals background image is fixed not dependent on page
                        let bgPromotionalsData = (data?.data?.filter(item => item?.page_list_id?.name === "download_app_bg"));
                        setPromotionalsBG(bgPromotionalsData);

                        addDataIntoCache('promotionalsBGCache', `${rootUrl}/statics/banners/download_app_bg`, {
                            data: bgPromotionalsData,
                            updateDate: new Date()
                        });
                    }
                })
        };
        fetchStaticsData();
        dispatch(setInnerLoaderFalse());
    }, [pageName, dispatch]);

    useEffect(() => {
        const fetchGetStartedData = async () => {
            const cacheDataOfGetStartedBanner = await getCacheData('getStartedCache', `${rootUrl}/statics/get-started/banners/`);

            if (cacheDataOfGetStartedBanner) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfGetStartedBanner = cacheDataOfGetStartedBanner?.updateDate; /* Get the cached time from wherever it's stored */

                // Convert the cached time string to a Date object
                const cachedTimeOfGetStartedBanner = new Date(cachedTimeStringOfGetStartedBanner);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfGetStartedBanner = currentTime - cachedTimeOfGetStartedBanner;

                if (timeDifferenceOfGetStartedBanner < cacheExpirationThreshold) {
                    setGetStartedData(cacheDataOfGetStartedBanner?.data);
                    setGetStartedDataState(false);
                    return null;
                }
            }

            try {
                let response = await fetch(`${rootUrl}/statics/get-started/banners/`);

                if (!response.ok) throw response;
                else {
                    let data = await response.json();
                    if (data?.status === "ok") {
                        // console.log(data?.data);
                        if (!data?.data?.length) {
                            localStorage.setItem("getStartAccess", true);
                        }
                        addDataIntoCache(`getStartedCache`, `${rootUrl}/statics/get-started/banners/`, {
                            data: data?.data,
                            updateDate: new Date()
                        });
                        setGetStartedData(data?.data);
                    }
                    return data;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setGetStartedDataState(false);
            }
        }
        fetchGetStartedData();
    }, []);

    useEffect(() => {
        const progressTimer = 500;
        if (
            getStartedDataState === false &&
            // getStartedData?.length > 0 &&
            logo &&
            appBG?.length > 0
        ) {
            if (percentage === 100) {
                setTimeout(() => {
                    setProgressBar(false);
                }, progressTimer);
            } else {
                setPercentage(100);
            }
        }
        else {
            setTimeout(() => {
                setPercentage(80);
            }, progressTimer);
        }
    }, [logo, appBG, percentage, getStartedDataState,]);

    return {
        mirrorLinkData,
        logo,
        sliderImgList,
        appBG,
        promotionalsBG,
        progressBar,
        percentage,
        getStartedData,
        getStartedDataState,
        getStartRef,
        setTrueStart,
    };
};