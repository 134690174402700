const home = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Light/home.svg";
const sports = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Light/sports.svg";
const casino = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Light/casino.svg";
const lottery = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Light/lottery.svg";
const more = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Light/more.svg";

const homeDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Dark/home.svg";
const sportsDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Dark/sports.svg";
const casinoDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Dark/casino.svg";
const lotteryDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Dark/lottery.svg";
const moreDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/BottomNavigationIcons/Dark/more.svg";

// import { Trans } from 'react-i18next';

export const bottomNavList = [
    {
        id: 0,
        name: "Home",
        navName: "homeBottomNav",
        lightImage: home,
        darkImage: homeDark,
        link: `/`,
    },
    {
        id: 1,
        name: "Sports",
        navName: "sportsBottomNav",
        lightImage: sports,
        darkImage: sportsDark,
        link: `/sports`,
    },
    {
        id: 2,
        name: "Casino",
        navName: "casinoBottomNav",
        lightImage: casino,
        darkImage: casinoDark,
        link: `/casino`,
    },
    {
        id: 3,
        name: "Lottery",
        navName: "lotteryBottomNav",
        lightImage: lottery,
        darkImage: lotteryDark,
        link: `/lottery`,
    },
    {
        id: 4,
        name: "More",
        navName: "moreBottomNav",
        lightImage: more,
        darkImage: moreDark,
        link: `/account`,
    },
];