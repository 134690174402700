import React from "react";
import LotteryNavigation from "../../Components/Shared/Navigation/LotteryNavigation";
import { useStatics } from "../../hooks/useStatics";

import { motion } from "framer-motion"

const GetStarted = React.lazy(() => import("../../Pages/GetStarted/GetStarted"));

const LotteryBaseLayout = ({ children }) => {

    const { getStartRef, setTrueStart } = useStatics();

    return (
        <div>
            {
                getStartRef === null ?
                    <GetStarted setTrueStart={setTrueStart} />
                    :
                    <div className="home">
                        <motion.div
                            initial={{ opacity: 0, x: "-50%" }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: "50%" }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            <div style={{ minHeight: "calc(100dvh - 63px)" }}>

                                <div className="sticky-top">
                                    <LotteryNavigation />
                                </div>
                                {children}
                            </div>
                        </motion.div>
                    </div>
            }
        </div>
    );
};

export default LotteryBaseLayout;