import React from 'react';

const DetailsDataShow = ({ item, }) => {
    // console.log(item);
    return (
        <>
            <div className="row gx-0 gy-2 my-2 w-100 d-flex align-items-center justify-content-between">
                {Object.keys(item)?.map(function (property, key) {
                    // console.log(item, property, key);
                    return (
                        <>
                            <div className="col-6 text-start">
                                <div className="">{property} :</div>
                            </div>
                            <div className="col-6 text-end">
                                <div className="">{item[property]}</div>
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    );
};

export default DetailsDataShow;