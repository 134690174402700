const useTime = () => {
    const convertDate = (data) => {
        var date = new Date(`${data}`);
        return date.toLocaleDateString();
    }

    const convertTime = (data) => {
        var date = new Date(`${data}`);
        return date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }

    return {
        convertDate,
        convertTime
    }
}

export default useTime