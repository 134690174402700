import { createSlice } from "@reduxjs/toolkit";

const customSweetAlertSlice = createSlice({
    name: "customSweetAlert",
    initialState: {
        show: false,
        message: null,
        type: null
    },
    reducers: {
        showCustomSweetAlert: (state, action) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },

        closeCustomSweetAlert: (state, action) => {
            state.show = false;
            state.message = null;
            state.type = null;
        }
    }
})

export const customSweetAlertData = state => {
    const data = {
        show: state.customSweetAlert?.show,
        message: state.customSweetAlert?.message,
        type: state.customSweetAlert?.type
    }
    return data?.show ? data : state?.customSweetAlert;
};

export const { showCustomSweetAlert, closeCustomSweetAlert } = customSweetAlertSlice.actions;

export default customSweetAlertSlice.reducer;